// Utilities
import axios from '../../services/http-common'
const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    username: localStorage.getItem('user') || '',
    permissions: localStorage.getItem('permissions') || [],
}

const mutations = {
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, user) {
        state.status = 'success'
        state.token = user.token
        state.refreshToken = user.refreshToken
        state.username = user.user
        state.permissions = user.permissions
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
        state.permissions = []
    },
}

const actions = {
    login: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/Auth/login', user)
                .then((response) => {
                    const userData = response.data
                    localStorage.setItem('token', userData.token)
                    commit('auth_success', userData)
                    resolve(response)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    
                    reject(err)
                })
        })
    },

    getAccessToken: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            this.$http.post('/Auth/RefreshToken', { token: localStorage.getItem('refreshToken') })
                .then((response) => {
                    const userData = response.data
                    localStorage.setItem('token', userData.token)
                    localStorage.setItem('user', userData.user)
                    localStorage.setItem('permissions', userData.permissions)
                    localStorage.setItem('refreshToken', userData.refreshToken)
                    commit('auth_success', userData)
                    resolve(response)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('user')
                    localStorage.removeItem('permissions')
                    reject(err)
                })
        })
    },

    logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('permissions')
            resolve()
        })
    },
}

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    permissions: state => state.permissions,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
