// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("../layouts/default/Index"),
      children: [
        {
          path: "",
          name: "Dashboard",
          component: () => import("../views/Dashboard"),
          meta: {
            requiresAuth: true
          }
        },
        // {
        //   path: "users",
        //   name: "Users",
        //   component: () => import("../views/Users"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "covid-tracking",
        //   name: "Covid Tracking",
        //   component: () => import("../views/CovidTracking"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "balance-account",
        //   name: "Balance Account",
        //   component: () => import("../views/BalanceAccount"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "balance-account-2",
        //   name: "Balance Account Copy",
        //   component: () => import("../views/BalanceAccount copy.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "wristbands",
        //   name: "Wristbands",
        //   component: () => import("../views/Wristbands"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "my-club",
        //   name: "My Club",
        //   component: () => import("../views/NightClub"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "sponsors",
        //   name: "Sponsors",
        //   component: () => import("../views/Sponsors"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        // {
        //   path: "products",
        //   name: "Products",
        //   component: () => import("../views/Products"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        // {
        //   path: "devices",
        //   name: "Devices",
        //   component: () => import("../views/Devices.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "guests",
        //   name: "Guests",
        //   component: () => import("../views/Guests.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "events",
        //   name: "Events",
        //   component: () => import("../views/Events.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "events/:id",
        //   name: "Events / Balance Accounts",
        //   component: () => import("../views/BalanceAccount.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        // {
        //   path: "events/balance-account/:id",
        //   name: "Events / Balance Accounts / Wristbands",
        //   component: () => import("../views/Wristbands.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        // {
        //   path: "events/balance-account/wristbands/:id",
        //   name: "Events / Balance Accounts / Wristbands / Wrsitband Info",
        //   component: () => import("../views/Wristband Info.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },

        // {
        //   path: "sponsors/:id",
        //   name: "Sponsors",
        //   component: () => import("../views/Guests.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "sponsors/guests/:id",
        //   name: "Sponsors / Guests / Guest Info",
        //   component: () => import("../views/Guest Info.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "sponsors/balance-account/:id",
        //   name: "Sponsors / Balance Account / Balance Account Info",
        //   component: () => import("../views/BalanceAccount Info.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "products-category",
        //   name: "Products Category",
        //   component: () => import("../views/Products Category"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "products-category/:id",
        //   name: "Products Category / Products ",
        //   component: () => import("../views/Products.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        // {
        //   path: "products-category/products/:id",
        //   name: "Products Category / Products / Product Info",
        //   component: () => import("../views/Product Info.vue"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        {
          path: "stores",
          name: "Stores",
          component: () => import("../views/Stores.vue")
        },

        {
          path: "stores/:id",
          name: "Stores / Zones",
          component: () => import("../views/Zones.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "stores/zones/:id",
          name: "Stores / Zones / Locations",
          component: () => import("../views/Locations.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "stores/zones/locations/:id",
          name: "Stores / Zones / Locations / Locations Info",
          component: () => import("../views/Location Info.vue"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "stock-invoice",
          name: "Stock Invoice",
          component: () => import("../views/Stock Invoice"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "stock-invoice/:id",
          name: "Stock Invoice / Stock Products",
          component: () => import("../views/Stock Products"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "stock-invoice/stock-products/:id",
          name: "Stock Invoice / Stock Products / Stock Product Info",
          component: () => import("../views/Stock Product Info"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "assets-category",
          name: "Assets Category",
          component: () => import("../views/Assets Category"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "assets-category/:id",
          name: "Assets Category / Assets Type",
          component: () => import("../views/Assets Category Type"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "assets-category/assets-type/:id",
          name: "Assets Category / Assets Type / Assets",
          component: () => import("../views/Assets Type Assets"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "assets-type",
          name: "Assets Types",
          component: () => import("../views/Assets Type"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "assets-type/:id",
          name: "Assets Types / Assets",
          component: () => import("../views/Assets Type Assets"),
          meta: {
            requiresAuth: true
          }
        },


        {
          path: "assets-invoice",
          name: "Asset Invoice",
          component: () => import("../views/Assets Invoice"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "assets-invoice/:id",
          name: "Asset Invoice / Asset Stock",
          component: () => import("../views/Assets Stock"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "assets-invoice/assets-stock/:id",
          name: "Asset Invoice / Asset Stock / Assets",
          component: () => import("../views/Assets.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "assets-invoice/assets-stock/assets/:id",
          name: "Assets Invoice / Assets Stock / Assets / Info ",
          component: () => import("../views/Assets Info.vue"),
          meta: {
            requiresAuth: true
          }
        },
        // {
        //   path: "assets-invoice/assets-stock/:id",
        //   name: "Asset Invoice / Asset Stock / Stock Info",
        //   component: () => import("../views/AssetStock Info"),
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        {
          path: '/jobs',
          name: 'Jobs',
          component: () => import('../views/Jobs.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/staff',
          name: 'Staff',
          component: () => import('../views/Staff.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/material',
          name: 'Materials',
          component: () => import('../views/Material.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/material-stock-invoices',
          name: 'Materials Stock Invoices',
          component: () => import('../views/MaterialInvoice.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/material-stocks',
          name: 'Materials Stocks',
          component: () => import('../views/MaterialStocks.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/material-stock-takes',
          name: 'Materials Stock Takes',
          component: () => import('../views/MaterialStokeTake.vue'),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: '/suppliers',
          name: 'Suppliers',
          component: () => import('../views/Suppliers.vue'),
          meta: {
            requiresAuth: false,
          },
        },
      ]
    },
    {
      path: "/login",
      component: () => import("../layouts/page/Index"),
      children: [
        {
          path: "",
          name: "Login",
          component: () => import("../views/Login")
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['authUser/isLoggedIn']) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
});

function hasAccess(name) {
  var permissions = localStorage.getItem("permissions");

  switch (name) {
    case "Dashboard":
      return true;
    case "Devices":
      return true;
    case "Users":
      return permissions.includes("Permissions.Users.ViewAll");
    case "Settings":
      return permissions.includes("Permissions.Settings.ViewAll");
    default:
      return false;
  }
}

export default router;
