// Pathify
import { make } from "vuex-pathify";

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: true,
  items: [
    // {
    //   title: "Dashboard",
    //   icon: "mdi-view-dashboard",
    //   to: "/"
    // },
    // {
    //   title: "My Club",
    //   icon: "mdi-alpha-m ",
    //   to: "/my-club"
    // },
    // {
    //   title: "Sponsors",
    //   icon: "mdi-alpha-s ",
    //   to: "/sponsors"
    // },
    // {
    //   title: "Events",
    //   icon: "mdi-alpha-e ",
    //   to: "/events"
    // },
    // {
    //   title: "Products Category",
    //   icon: "mdi-alpha-p ",
    //   to: "/products-category"
    // },
    {
      title: "Stores",
      icon: "mdi-alpha-s ",
      to: "/stores"
    },

    // {
    //   title: "Product Stocks",
    //   icon: "mdi-alpha-p ",
    //   to: "/stock-invoice"
    // },

    {
      title: "Assets Category",
      icon: "mdi-alpha-a ",
      to: "/assets-category"
    },

    // {
    //   title: "Assets Type",
    //   icon: "mdi-alpha-a ",
    //   to: "/assets-type"
    // },
    {
      title: "Assets Stock",
      icon: "mdi-alpha-a ",
      to: "/assets-invoice"
    },

    // {
    //   title: "Balance Account",
    //   icon: "mdi-alpha-b ",
    //   to: "/balance-account"
    // },
    // {
    //   title: "Events",
    //   icon: "mdi-alpha-e ",
    //   to: "/events"
    // },

    // {
    //   title: "Guests",
    //   icon: "mdi-alpha-g ",
    //   to: "/guests"
    // },
    // {
    //   title: "Wristbands",
    //   icon: "mdi-alpha-w ",
    //   to: "/wristbands"
    // },

    // {
    //   title: 'Assets',
    //   icon: 'mdi-alpha-a ',
    //   to: '/assets',
    // },
    // {
    //   title: 'Users',
    //   icon: 'mdi-account-group ',
    //   to: '/users/',
    // },
    // {
    //   title: 'Covid Tracking',
    //   icon: 'mdi-list-status ',
    //   to: '/covid-tracking/',
    // },
    // {
    //   title: 'User History',
    //   icon: 'mdi-account-details ',
    //   to: '/user-histories/',
    // },
    // {
    //   title: 'Logs',
    //   icon: 'mdi-post  ',
    //   to: '/logs/',
    // },
    {
      title: 'Materials',
      icon: 'mdi-alpha-m',
      to: '/material',
    },
    {
      title: 'Invoices',
      icon: 'mdi-alpha-i',
      to: '/material-stock-invoices',
    },
    {
      title: 'Stocks',
      icon: 'mdi-alpha-s',
      to: '/material-stocks',
    },
    {
      title: 'Stocktakes',
      icon: 'mdi-alpha-s',
      to: '/material-stock-takes',
    },
    {
      title: 'Jobs',
      icon: 'mdi-alpha-j',
      to: '/jobs',
    },
    {
      title: 'Staff',
      icon: 'mdi-alpha-s',
      to: '/staff',
    },
    {
      title: 'Suppliers',
      icon: 'mdi-alpha-s',
      to: '/suppliers',
    },
  ]
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
